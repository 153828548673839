import { ActionType } from '../actionTypes';
import { ThunkResult, ThunkDispatchType, FetchReturn} from '../types';

import { 
  AnalyticsEventsMeta, ConsolidatedLocation, ContactAnalysisResponse, EventAnalysisResponse,
  EventType, ExecutiveContact, ExecutiveContactCategory, FetchEventAnalysisPayload,
  FetchLeaderAnalysisQuery, RecurrenceType, RootState, EventCategory
} from '..';
import api from '../../api';
import { DateTime } from 'luxon';
import { PermissionError, sendPermissionDeniedMessage } from '../../utils/permissionUtils';
import { AnalyticsEventColor, LeaderEvent } from '../cabinetApi/generated/analytics';


export const fetchAnalyticsEventsStateless = (
  query: Record<string, string | string[] | undefined | number | number[]>
): ThunkResult<Promise<FetchReturn<
  {data: {[key: number]: LeaderEvent}, meta: {contacts: {[key: number]: ExecutiveContact}}},
  PermissionError
>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<
    {data: {[key: number]: LeaderEvent}, meta: {contacts: {[key: number]: ExecutiveContact}}},
    PermissionError
  >> => {
    const response = await api.fetchAnalyticsEventUnpaginated(query);
    return response;
  };

export const fetchConsolidateLocations = (): ThunkResult<Promise<
  FetchReturn<{[key: string]: ConsolidatedLocation}, PermissionError>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<{[key: string]: ConsolidatedLocation}, PermissionError>> => {
    const response = await api.fetchConsolidateLocations();
    if (response.status === 200) {
      dispatch({type: ActionType.UPDATE_CONSOLIDATED_LOCATION, locations: response.data});
    } else if (response?.status === 403) {
      sendPermissionDeniedMessage(dispatch, response);
    }
    return response;
  };

export const fetchRecurrenceTypes = (): ThunkResult<Promise<
  FetchReturn<{[key: string]: RecurrenceType}, PermissionError>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<{[key: string]: RecurrenceType}, PermissionError>> => {
    const response = await api.fetchRecurrenceTypes();
    if (response.status === 200) {
      dispatch({type: ActionType.UPDATE_RECURRENCE_TYPE, types: response.data});
    } else if (response?.status === 403) {
      sendPermissionDeniedMessage(dispatch, response);
    }
    return response;
  };

export const updateAnalyticsEvent = (
  event: Partial<LeaderEvent> & Pick<LeaderEvent, 'id'>, updateAll: boolean
): ThunkResult<Promise<FetchReturn<{[key: number]: LeaderEvent}, PermissionError>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<{[key: number]: LeaderEvent}, PermissionError>> => {

    const recurringEventChanges = {...getState().eventAnalytics.events};
    recurringEventChanges[event.id] = {...recurringEventChanges[event.id], ...event};
    if (updateAll) {
      Object.values(getState().eventAnalytics.events).forEach((updatedEvent) => {
        if (
          event?.recurring_root_event_idr && (updatedEvent.recurring_root_event_idr === event?.recurring_root_event_idr)
        ) {
          if (event?.event_category || event?.event_category === null) {
            recurringEventChanges[updatedEvent.id].event_category = event?.event_category;
          }
          if (event?.event_type || event?.event_type === null) {
            recurringEventChanges[updatedEvent.id].event_type = event?.event_type;
          }
          if (event?.inclusion || event?.inclusion === null) {
            recurringEventChanges[updatedEvent.id].inclusion = event?.inclusion;
          }
        }
      });
    }
    
    dispatch({ type: ActionType.UPDATE_ANALYTICS_EVENTS, payload:{events: recurringEventChanges, order: []}});
    

    const res = await api.updateAnalyticsEvent(event, updateAll).then((response) => {
      return response;
    });

    if (res?.status === 403) {
      sendPermissionDeniedMessage(dispatch, res);
    }

    return res;

    
  };

export const fetchAnalyticsEventCategories = (
): ThunkResult<Promise<FetchReturn<{ [key: string]: EventCategory }, PermissionError>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<{ [key: string]: EventCategory }, PermissionError>> => {
    const response = await api.fetchAnalyticsEventCategories();
    if (response.status === 200) {
      dispatch({ type: ActionType.UPDATE_EVENT_CATEGORIES, categories: response.data });
    } else if (response?.status === 403) {
      sendPermissionDeniedMessage(dispatch, response);
    }
    return response;
  };

export const createAnalyticsEventCategory = (
  data: EventCategory
): ThunkResult<Promise<FetchReturn<EventCategory, PermissionError>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<EventCategory, PermissionError>> => {
    const response = await api.createAnalyticsEventCategory(data);
    if (response.status === 201) {
      dispatch({ type: ActionType.UPDATE_EVENT_CATEGORIES, categories: {[response.data.id]: response.data }});
    } else if (response?.status === 403) {
      sendPermissionDeniedMessage(dispatch, response);
    }
    return response;
  };

export const updateAnalyticsEventCategory = (
  data: Partial<EventCategory>& Pick<EventCategory, 'id'>
): ThunkResult<Promise<FetchReturn<EventCategory, PermissionError>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<EventCategory, PermissionError>> => {
    const response = await api.updateAnalyticsEventCategory(data);
    if (response.status === 200) {
      dispatch({ type: ActionType.UPDATE_EVENT_CATEGORIES, categories: {[response.data.id]: response.data }});
    } else if (response?.status === 403) {
      sendPermissionDeniedMessage(dispatch, response);
    }
    return response;
  };

export const deleteAnalyticsEventCategory = (categoryId: number): ThunkResult<Promise<
  FetchReturn<EventCategory, PermissionError>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<EventCategory, PermissionError>> => {
    const response = await api.deleteAnalyticsEventCategory(categoryId);
    if (response.status === 204) {
      dispatch({ type: ActionType.DELETED_EVENT_CATEGORY, categoryId });
    } else if (response?.status === 403) {
      sendPermissionDeniedMessage(dispatch, response);
    }
    return response;
  };

export const fetchAnalyticsEventTypes = (): ThunkResult<Promise<
  FetchReturn<{[key: string]: EventType}, PermissionError>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<{[key: string]: EventType}, PermissionError>> => {
    const response = await api.fetchAnalyticsEventTypes();
    if (response.status === 200) {
      dispatch({type: ActionType.UPDATE_EVENT_TYPES, types: response.data});
    } else if (response?.status === 403) {
      sendPermissionDeniedMessage(dispatch, response);
    }
    return response;
  };

export const createAnalyticsEventType = (
  data: EventType
): ThunkResult<Promise<FetchReturn<EventType, PermissionError>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<EventType, PermissionError>> => {
    const response = await api.createAnalyticsEventType(data);
    if (response.status === 201) {
      dispatch({ type: ActionType.UPDATE_EVENT_TYPES, types: {[response.data.id]: response.data }});
    } else if (response?.status === 403) {
      sendPermissionDeniedMessage(dispatch, response);
    }
    return response;
  };

export const updateAnalyticsEventType = (
  data: Partial<EventType>& Pick<EventType, 'id'>
): ThunkResult<Promise<FetchReturn<EventType, PermissionError>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<EventType, PermissionError>> => {
    const response = await api.updateAnalyticsEventType(data);
    if (response.status === 200) {
      dispatch({ type: ActionType.UPDATE_EVENT_TYPES, types: {[response.data.id]: response.data }});
    } else if (response?.status === 403) {
      sendPermissionDeniedMessage(dispatch, response);
    }
    
    return response;
  };

export const deleteAnalyticsEventType = (typeId: number): ThunkResult<
  Promise<FetchReturn<EventType, PermissionError>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<EventType, PermissionError>> => {
    const response = await api.deleteAnalyticsEventType(typeId);
    if (response.status === 204) {
      dispatch({ type: ActionType.DELETED_EVENT_TYPE, typeId });
    } else if (response?.status === 403) {
      sendPermissionDeniedMessage(dispatch, response);
    }
    return response;
  };

export const fetchAnalyticsExecutiveContactCategories = (
): ThunkResult<
  Promise<FetchReturn<{[key: string]: ExecutiveContactCategory}, PermissionError>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<{[key: string]: ExecutiveContactCategory}, PermissionError>> => {
    const response = await api.fetchAnalyticsExecutiveContactCategory();
    if (response.status === 200) {
      dispatch({type: ActionType.UPDATE_EXECUTIVE_CONTACT_CATEGORIES, categories: response.data});
    } else if (response?.status === 403) {
      sendPermissionDeniedMessage(dispatch, response);
    }
    return response;
  };

export const createAnalyticsExecutiveContactCategory = (
  data: ExecutiveContactCategory
): ThunkResult<Promise<FetchReturn<ExecutiveContactCategory, PermissionError>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<ExecutiveContactCategory, PermissionError>> => {
    const response = await api.createAnalyticsExecutiveContactCategory(data);
    if (response.status === 201) {
      dispatch({ 
        type: ActionType.UPDATE_EXECUTIVE_CONTACT_CATEGORIES,
        categories: {[response.data.id]: response.data }
      });
    } else if (response?.status === 403) {
      sendPermissionDeniedMessage(dispatch, response);
    }
    return response;
  };

export const updateAnalyticsExecutiveContactCategory = (
  data: Partial<ExecutiveContactCategory>& Pick<ExecutiveContactCategory, 'id'>
): ThunkResult<Promise<FetchReturn<ExecutiveContactCategory, PermissionError>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<ExecutiveContactCategory, PermissionError>> => {
    const response = await api.updateAnalyticsExecutiveContactCategory(data);
    if (response.status === 200) {
      dispatch({ 
        type: ActionType.UPDATE_EXECUTIVE_CONTACT_CATEGORIES, 
        categories: {[response.data.id]: response.data }});
    } else if (response?.status === 403) {
      sendPermissionDeniedMessage(dispatch, response);
    }
    return response;
  };
  
export const deleteAnalyticsExecutiveContactCategory = (categoryId: number): 
ThunkResult<Promise<FetchReturn<ExecutiveContactCategory, PermissionError>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<ExecutiveContactCategory, PermissionError>> => {
    const response = await api.deleteAnalyticsExecutiveContactCategory(categoryId);
    if (response.status === 204) {
      dispatch({ type: ActionType.DELETED_EXECUTIVE_CONTACT_CATEGORIES, categoryId });
    } else if (response?.status === 403) {
      sendPermissionDeniedMessage(dispatch, response);
    }
    return response;
  };

export const fetchEventAnalysis = (
  data: FetchEventAnalysisPayload
): ThunkResult<Promise<FetchReturn<EventAnalysisResponse, PermissionError>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<EventAnalysisResponse, PermissionError>> => {
    const response = await api.fetchEventAnalysis(data);
    if (response.status === 200) {
      dispatch({type: ActionType.SET_EVENT_ANALYSIS_RESPONSE, analysis: response.data});
    } else if (response?.status === 403) {
      sendPermissionDeniedMessage(dispatch, response);
    }
    return response;
  };

export const fetchContactAnalysis = (
  data: FetchLeaderAnalysisQuery
): ThunkResult<
  Promise<FetchReturn<ContactAnalysisResponse, PermissionError> | undefined>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<ContactAnalysisResponse, PermissionError> | undefined> => {
    const response = await api.fetchContactAnalysis(data);
    if (response?.status === 200) {
      dispatch(
        { type: ActionType.UPDATE_EXECUTIVE_CONTACTS_ANALYSIS,
          contacts: response.data.attendees
        });
    } else if (response?.status === 403) {
      sendPermissionDeniedMessage(dispatch, response);
    }
    return response;
  };


export const updateAnalyticsExecutiveContact = (
  data: Partial<ExecutiveContact> & {id: number}
): ThunkResult<Promise<FetchReturn<ExecutiveContact, PermissionError>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<ExecutiveContact, PermissionError>> => {
    const response = await api.updateAnalyticsExecutiveContact(data);
    if (response.status === 200) {
      dispatch({
        type: ActionType.UPDATE_EXECUTIVE_CONTACTS_RECONCILIATION,
        contacts: {[response.data.id]: response.data}
      });
      dispatch({
        type: ActionType.UPDATE_EXECUTIVE_CONTACTS_ANALYSIS,
        contacts: {[response.data.id]: response.data}
      });
    } else if (response?.status === 403) {
      sendPermissionDeniedMessage(dispatch, response);
    }
    return response;
  };

export const clearExecutiveContacts = (): ThunkResult<Promise<void>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<void> => {
    dispatch({type: ActionType.UPDATE_EXECUTIVE_CONTACTS_RECONCILIATION, contacts: {}});
    dispatch({type: ActionType.UPDATE_EXECUTIVE_CONTACTS_ANALYSIS, contacts: {}});
  };

export const clearAnalyticsEvents = (): ThunkResult<Promise<void>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<void> => {
    dispatch({type: ActionType.UPDATE_ANALYTICS_EVENTS, payload: {
      events: {},
      order: [],
      meta: {
        min_start_date: null,
        max_end_date: null,
        most_recent_log:  null,
        most_recent_started_log_date: null,
        most_recent_completed_log_date: null,
        most_recent_queued_log_date: null
      }
    }});
  };

export const clearAnalyticsEventAnalysis = (): ThunkResult<Promise<void>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<void> => {
    dispatch({type: ActionType.SET_EVENT_ANALYSIS_RESPONSE, analysis: undefined});
  };

export const fetchAnalyticsExecutiveContactTypes = (): ThunkResult<Promise<void>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<void> => {
    const res = await api.fetchAnalyticsExecutiveContactTypes();
    if (res.status === 200) {
      dispatch({type: ActionType.SET_EXECUTIVE_CONTACT_TYPES, types: res.data});
    } else if (res?.status === 403) {
      sendPermissionDeniedMessage(dispatch, res);
    }
  };

export const resyncAnalyticsEvents = (): ThunkResult<
  Promise<FetchReturn<
    Omit<AnalyticsEventsMeta, "min_start_date" | "max_end_date">, PermissionError>>
> => 
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<
    FetchReturn<
      Omit<AnalyticsEventsMeta, "min_start_date" | "max_end_date">, PermissionError>> => {
    const res = await api.resyncAnalyticsEvents();
    if (res.status === 200) {
      res.data.most_recent_queued_log_date = DateTime.now().toISO();
      dispatch({type: ActionType.UPDATE_ANALYTICS_META, meta: res.data});
    } else if (res?.status === 403) {
      sendPermissionDeniedMessage(dispatch, res);
    }
    
    return res;
  };

export const fetchAnalyticsAnalysisInclusions = (): ThunkResult<Promise<void>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<void> => {
    const res = await api.fetchAnalyticsAnalysisInclusions();
    if (res.status === 200) {
      dispatch({type: ActionType.SET_ANALYSIS_INCLUSIONS, inclusions: res.data});
    } else if (res?.status === 403) {
      sendPermissionDeniedMessage(dispatch, res);
    }
  };

export const fetchAnalyticsEventColor = (
  colorGridFilterParameters: Record<string, string | string[] | undefined | number | number[]>
): ThunkResult<Promise<FetchReturn<
  {data: {[key: string]: AnalyticsEventColor}},
  PermissionError
>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<
    FetchReturn<{data: {[key: string]: AnalyticsEventColor}}, PermissionError>> => {
    const res = await api.fetchAnalyticsEventColors(colorGridFilterParameters);
    if (res.status === 200) {
      dispatch({type: ActionType.SET_ANALYTICS_COLORS, colors: res.data});
    } else if (res?.status === 403) {
      sendPermissionDeniedMessage(dispatch, res);
    }
    return res;
  };

export const updateAnalyticsEventColor = (
  data: Partial<AnalyticsEventColor> & { id: number }
): ThunkResult<Promise<FetchReturn<
  AnalyticsEventColor,
  PermissionError
>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<AnalyticsEventColor, PermissionError>> => {
    const colors = [...getState().eventAnalytics.analytics_colors];
    const res = await api.updateAnalyticsEventColor(data);
    if (res.status === 200) {
      colors[Number(colors.findIndex(color => color.id === res.data.id))] = res.data;
      dispatch({ type: ActionType.SET_ANALYTICS_COLORS, colors: colors });
    } else if (res?.status === 403) {
      sendPermissionDeniedMessage(dispatch, res);
    }
    return res;
  };



